import axios from "axios";
import { Endpoints } from "./endpoints";
import { DriverUtils } from "../../driver/driver_utils";

interface UploadImageResponse {
    url?: string;
    message: string;
}

export enum HttpMethod {
    get = "GET",
    post = "POST",
    put = "PUT",
    delete = "DELETE",
}

export default class ApiService {
    static get host() {
        return process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "driver-staging" ?
            "https://bo-api-staging.uenafood.com" :
            "https://bo-api.uenafood.com";
    }

    static driverApiFetching<P extends {}, R>(
        endpoint: Endpoints,
        args: {
            httpMethod?: HttpMethod,
            parameters?: P,
            onSuccess: (response: R) => void,
            onFailure: (error: any, errorCode?: number) => void,
        }
    ) {
        const signInValues = DriverUtils.getSignInValues();

        const onSuccess: (response: R) => void = (response) => {
            console.log(response)
            if ((response as any).isUserValid === false) {
                DriverUtils.logOut();
            } else {
                args.onSuccess(response);
            }
        }

        const driverId = ((args.parameters as any)?.driverId ?? signInValues?.driverId)?.toUpperCase();

        this.fetch(
            endpoint, {
            httpMethod: args.httpMethod,
            parameters: {
                ...args.parameters,
                driverId
            },
            onSuccess,
            onFailure: args.onFailure
        })
    }

    static fetch<P extends {}, R>(
        endpoint: Endpoints,
        args: {
            httpMethod?: HttpMethod,
            parameters?: P,
            onSuccess: (response: R) => void,
            onFailure: (error: any, errorCode?: number) => void,
        }
    ) {
        const timeOutMilis = 300000;
        const timeOutController = new AbortController();
        const timoOutControllerId = setTimeout(() => timeOutController.abort(), timeOutMilis);
        const body = JSON.stringify(args.parameters);

        try {
            fetch(`${this.host}${endpoint}`, {
                method: args.httpMethod ?? HttpMethod.post,
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                body: body,
                signal: timeOutController.signal
            }).then(
                response => {
                    return response.json();
                },
                error => {
                    args.onFailure(error);
                    clearTimeout(timoOutControllerId);
                }
            ).then(
                response => {
                    args.onSuccess(response);
                },
                error => {
                    args.onFailure(error);
                    clearTimeout(timoOutControllerId);
                }
            );
        } catch (error) {
            args.onFailure(error);
            clearTimeout(timoOutControllerId);
        }

        return timeOutController;
    }

    static uploadFile(
        endpoint: Endpoints,
        args: {
            file: File,
            bucketName: string,
            folderName: string,
            httpMethod?: HttpMethod,
            onSuccess: (response: UploadImageResponse) => void,
            onFailure: (error: any, errorCode?: number) => void
        }
    ) {
        const timeOutMilis = 300000;
        const timeOutController = new AbortController();
        const timoOutControllerId = setTimeout(() => timeOutController.abort(), timeOutMilis);

        const formData = new FormData();

        formData.append("image", args.file);
        formData.append("name", args.file.name);
        formData.append("bucketName", args.bucketName);
        formData.append("folderName", args.folderName);

        try {
            axios.post(`${this.host}${endpoint}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(
                (response) => args.onSuccess(response.data),
                (error) => args.onFailure(error)
            );
        } catch (error) {
            args.onFailure(error);
            clearTimeout(timoOutControllerId);
        }
    }
}