import "./home.css";

import React from "react";
import Scaffold from "../commons/scaffold/scaffold";
import { HomeFeatureButton } from "./widgets/home_feature_button";

export default class HomeScreen extends React.Component {
    render() {
        return <Scaffold>
            <div id="HomeScreenContainer">
                <HomeFeatureButton title="Form Pemesanan (CS)" path="/create/order"/>
                <HomeFeatureButton title="Form Pemesanan (Kasir)" path="/create/order-cashier"/>
                <HomeFeatureButton title="Peta Area Layanan UENA" path="/service-area" />
            </div>
        </Scaffold>;
    }
}

