import React from "react";
import LoadingWidget from "./loading/loading_indicator_widget";

interface Props {
    title: string;
    isLoading?: boolean;
    backgroundColor?: string;
    textColor?: string;
    borderColor?: string;
    action?: () => void;
    height?: number;
    disabled?: boolean;
}

export default class FormButton extends React.Component<Props> {
    render() {
        return <button 
            className="FormButton"
            onClick={this.props.isLoading ? undefined : this.props.action} 
            disabled={this.props.disabled}
            style={{
                backgroundColor:this.props.backgroundColor ?? "white",
                color:this.props.textColor ?? "black",
                border:`1px solid ${this.props.borderColor ?? "transparent"}`,
                height:this.props.height
            }}
        >
            {
                (this.props.isLoading) ?
                    <LoadingWidget /> :
                    <>{this.props.title}</>
            }
        </button>;
    }
}