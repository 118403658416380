export enum UenaWebsiteActiveMenu {
    home,
    ourMission,
    ourMenu,
    ourQuality,
    howToOrder
}

export default class UenaWebsiteState {
    activeMenu: UenaWebsiteActiveMenu = UenaWebsiteActiveMenu.home;
    scrollPosition: number = 0;
    orderNowBottomInset: number = 32;
    isShowingTopModalMenu: boolean = false;
}