import "./notification_web_display.css";

import uena_logo from "../../assets/images/uena_form_header_white.svg";
import uena_bottom_logo from "../../assets/images/uena_vertical_logo.svg";
import download_google_play from "../../assets/images/download-google-play.webp";
import download_app_store from "../../assets/images/download-app-store.webp";

import ViewController from "../../commons/base/view_controller";
import Scaffold from "../../commons/scaffold/scaffold";
import NotificationWebDisplayState from "../view_models/notification_web_display_state";
import NotificationWebDisplayViewModel from "../view_models/notification_web_display_view_model";
import FormButton from "../../commons/ui/form_button";
import SizedDiv from "../../commons/ui/sized_div";

export default class NotificationWebDisplayScreen extends ViewController<{}, NotificationWebDisplayState, NotificationWebDisplayViewModel> {
    constructor(props: {}) {
        super(props, new NotificationWebDisplayViewModel());
    }

    render() {
        const response = this.vmState.response;
        const paragraph = response?.message.split("\n") ?? [];
        const errorMessage = this.vmState.errorMessage;

        return <Scaffold
            showTopAppBar={false}
            isShowLoading={this.vmState.isLoading}
            loadingBackgroundType="semi_transparent"
            loadingType="uena_logo"
            onlyForAdmin={false}
        >
            <div id="NotificationWebDisplayMainContainer">
                {/* APP BAR */}
                <div id="NotificationWebDisplayTopAppBar">
                    <a href="/">
                        <img src={uena_logo} alt="logo" />
                    </a>
                </div>

                {/* BODY */}
                <div id="NotificationWebDisplayBody">
                    {
                        (errorMessage && response) &&
                        <div id="NotificationWebDisplayErrorContainer">
                            <div>!</div>
                            <p>{errorMessage}</p>
                            <FormButton 
                                backgroundColor="#6B2C8F"
                                textColor="white"
                                title="Coba Muat Konten Kembali"
                                action={() => this.viewModel.loadContent()}
                            />
                        </div>
                    }
                    {
                        response &&
                        <>
                            {
                                response.imageUrl &&
                                <img src={response.imageUrl} alt="" />
                            }
                            <SizedDiv height={6} />
                            {
                                paragraph.map((text) => {
                                    return <p
                                        style={{
                                            textIndent: paragraph.length <= 1 ? 0 : 24
                                        }}
                                    >
                                        {text}
                                    </p>;
                                })
                            }
                            <SizedDiv height={16} />
                            {
                                response.actions.map((actionModel) => {
                                    return <>
                                        <FormButton 
                                            title={actionModel.label}
                                            backgroundColor="#FFCC33"
                                            textColor="#6B2C8F"
                                            action={() => window.location.href = actionModel.deeplink}
                                        />
                                        <SizedDiv height={12} />
                                    </>;
                                })
                            }
                        </>
                    }
                </div>

                {/* BOTTOM BAR */}
                <div id="NotificationWebDisplayBottomBar">
                    <img src={uena_bottom_logo} alt="logo-bottom" />
                    <div />
                    <div>
                        <p>UENA ©2023. All Rights Reserved.</p>
                        <div>
                            <a href="https://play.google.com/store/apps/details?id=com.uena.app">
                                <img src={download_google_play} alt="" />
                            </a>
                            <a href="https://apps.apple.com/id/app/uena-pujasera-online/id1638605441">
                                <img src={download_app_store} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Scaffold>;
    }
}