import React from "react";
import FormButton from "../../commons/ui/form_button";
import StringUtils from "../../commons/utils/string_utils";
import { CreateOrderPopUpModel, CreateOrderQuantityAndPriceModel } from "../cs/view_models/create_order_state";
import SizedDiv from "../../commons/ui/sized_div";
import { OrderFormValidationAdditionalItemModel } from "../models/responses/order_form_validation_response";

interface Props {
    popUpModel: CreateOrderPopUpModel;
    closePopUp: () => void;
    productList: CreateOrderQuantityAndPriceModel[];
    isDeliveredByDriver: boolean;
    additionalItems?: OrderFormValidationAdditionalItemModel[]
}

export default class CreateOrderPopUpContainerWidget extends React.Component<Props> {
    render() {
        let totalAmount = 0;
        const additionalItems = this.props.additionalItems;
        let totalAdditionalItemsPrice = 0;

        additionalItems?.forEach((model) => totalAdditionalItemsPrice += model.amount);

        this.props.productList.forEach((item) => {
            totalAmount += item.model.price * item.quantity;
        });

        return <div id="CreateOrderPopUpConfirmationBackground" onClick={() => this.props.closePopUp()}>
            <div onClick={(e) => e.stopPropagation()}>
                <p>Konfirmasi menu yang dipesan</p>
                <div>
                    {
                        this.props.productList.map((model) => <div id="CreateOrderPopUpConfirmationProductItemContainer">
                            <img src={model.model.imageUrl} alt="" />
                            <div>
                                <p>{model.model.title}</p>
                                <p>{`${StringUtils.getCurrencyStringFormat(model.model.price)} (x${model.quantity})`}</p>
                            </div>
                        </div>)
                    }
                </div>
                <div style={{
                    fontSize: 12.0,
                    fontWeight: 700
                }}>
                    <div className="CreateOrderPopUpPriceComponent">
                        <p>Subtotal</p>
                        <p>{StringUtils.getCurrencyStringFormat(totalAmount)}</p>
                    </div>
                    {
                        this.props.isDeliveredByDriver &&
                            <div className="CreateOrderPopUpPriceComponent">
                                <p>Biaya Pengantaran</p>
                                <p>{StringUtils.getCurrencyStringFormat(3000)}</p>
                            </div>
                    }
                    {
                        additionalItems && additionalItems.map((model) => 
                            <div className="CreateOrderPopUpPriceComponent">
                                <p>{model.name}</p>
                                <p style={{color: model.amount < 0 ? "red" : "black"}}>{StringUtils.getCurrencyStringFormat(model.amount)}</p>
                            </div>
                        )
                    }
                    <SizedDiv height={4} />
                    <div style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: "#3E3E3E"
                    }} />
                    <SizedDiv height={4} />
                    <div className="CreateOrderPopUpPriceComponent">
                        <p>Total yang Dibayarkan</p>
                        <p>{StringUtils.getCurrencyStringFormat(totalAmount + (this.props.isDeliveredByDriver ? 3000 : 0) + totalAdditionalItemsPrice)}</p>
                    </div>
                </div>
                <div>
                    <FormButton title="Batalkan" borderColor="#6B2C8F" textColor="#6B2C8F" action={() => this.props.closePopUp()}/>
                    <FormButton title="Konfirmasi" backgroundColor="#6B2C8F" textColor="white" action={() => {
                        this.props.popUpModel?.action();
                        this.props.closePopUp();
                    }}/>
                </div>
            </div>
        </div>;
    }
}