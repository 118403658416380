export enum Endpoints {
    // App Related Web View
    mobileQrisCheckStatus = "/app/qris_check",
    mobileQrisDisplay = "/app/qris_display",
    mobileQrisCheckOrder = "/app/qris_order_check",
    referralDetail = "/app/referral_detail",
    // Coverage Area
    coverageAreaPolygons = "/outlet/coverage_polygons",
    coverageAreaMapsLinkToCoordinate = "/outlet/maps_link_to_coordinate",
    // CRM
    notificationWebViewCreation = "/bo/create_notification_web",
    notificationWebViewDisplay = "/bo/get_notification_web",
    pushCrm = "/crm/push",
    redirectWebCreation = "/bo/create_redirect_web",
    redirectWebGet = "/bo/get_redirect_web",
    // Driver
    driverChangeStatus = "/driver/change_status",
    driverCancelDelivery = "/driver/cancel_delivery",
    driverDeliveryDone = "/driver/delivery_done",
    driverSignIn = "/driver/sign_in",
    driverGetOrderList = "/driver/get_orders",
    driverInputOrder = "/driver/input_order",
    driverOrderDetail = "/driver/order_detail",
    // Order Form
    orderFormAutoComplete = "/bo/form/create_order/auto_complete",
    orderFormGetContent = "/bo/form/create_order/contents",
    orderSaveDraft = "/bo/form/create_order/save_draft",
    orderFormSubmitRequest = "/bo/form/create_order/submit",
    orderFormValidateSubmission = "/bo/form/create_order/validate_submission",
    orderFormVoucherChecker = "/bo/form/create_order/voucher_checker",
    orderSendOtp = "/bo/form/create_order/send_otp_voucher",
    // Upload Image
    uploadImage = "/upload/image"
}